@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.container {
  background: var.$color-neutral-0;
  border-radius: 5px;
  box-shadow: 0 8.3px 67.1px -4.1px #00000021;
}

.title {
  padding: fn.spacing(2);
  border-bottom: 1px solid var.$color-neutral-75;
}

.body {
  padding: fn.spacing(2) fn.spacing(0);
  display: flex;
  flex-direction: column;
}

.body .list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: fn.spacing(0.6) fn.spacing(2);
}

.body .list[data-marker='true'] {
  background: var.$color-blue-50;
  color: var.$color-blue-400;
}

.list-values {
  text-align: right;
}
